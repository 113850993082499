import React, { useContext } from 'react';
import { AltContent } from '@GDM/AltContent';
import { Col, Row } from '@GDM/layout';
import { Map } from '@GDM/Map';
import Installation from '@utils/types/installation';
import { InstallationMap } from 'pages/ui/shared/maps';
import styles from '../characteristics.module.scss';
import { Panels } from '../shared';
import { Books } from './Books';
import { InstallationInfo } from './InstallationInfo';
import { installationInfoContext } from './InstallationInfo/installation-info.context';

export const InstallationCharacteristics = ({ installation }: { installation: Installation }) => {
  const { lat, lng } = useContext(installationInfoContext);

  if (!installation) return <AltContent />;

  return (
    <>
      <Row>
        {!!lat && !!lng && (
          <Col xl={4} className={styles['map-container']}>
            <Map center={[Number(lat), Number(lng)]}>
              <InstallationMap name={installation.name} lat={lat} lng={lng} />
            </Map>
          </Col>
        )}
        <Col>
          <InstallationInfo installation={installation} />
        </Col>
      </Row>

      <div className={styles.configs}>
        <div className={styles.column}>
          <Books installation={installation} />
        </div>
        <div className={styles.column}>
          {installation.energy === 'solar' && installation.has_meter && (
            <div className={styles.item}>
              <Panels meterName={installation.name} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
