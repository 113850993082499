import React, { useState } from 'react';
import ControlledRadioButtons from '@components/FormInputs/ControlledRadioButtons';
import ControlledSelect from '@components/FormInputs/ControlledSelect';
import { Button } from '@GDM/Button/Button';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Control } from 'react-hook-form';
import { FOCUS_OPTIONS } from '../constants';
import styles from '../styles.module.scss';
import { RiskAnalysisFormFilters } from '../types';

export const RiskAnalysisValuationReportHeader = ({
  exportUrl,
  control,
  focus: [primaryFocus, secondaryFocus],
  metricGroup,
}: {
  exportUrl: string;
  control: Control<RiskAnalysisFormFilters>;
  focus: [string, string | undefined];
  metricGroup: RiskAnalysisFormFilters['metricGroup'];
}) => {
  const [isNestedMode, setIsNestedMode] = useState(false);

  return (
    <div className={classNames(styles['display-filters'], 'pt-3')}>
      <div className="d-flex gap-2">
        <ControlledSelect
          name="primaryFocus"
          control={control}
          options={FOCUS_OPTIONS.filter(({ value }) => value !== secondaryFocus)}
          placeholder="common.focus"
          className={styles.select}
        />
        {isNestedMode ? (
          <>
            <ControlledSelect
              name="secondaryFocus"
              control={control}
              options={FOCUS_OPTIONS.filter(({ value }) => value !== primaryFocus)}
              placeholder="common.focus"
              isClearable
              className={styles.select}
              shouldUnregister
            />
            <Button
              icon="Trash2"
              variant="secondary"
              tooltip="common.delete"
              onClick={() => setIsNestedMode(false)}
              className="ml-1"
              size="xs"
              floating
            />
          </>
        ) : (
          <Button
            icon="Plus"
            text="risk_analysis.add_focus"
            onClick={() => setIsNestedMode(true)}
            variant="link"
            size="sm"
          />
        )}
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div className="d-flex gap-4">
          <ControlledRadioButtons
            name="metricGroup"
            control={control}
            options={[
              { label: 'risk_analysis.all', value: 'all' },
              { label: 'risk_analysis.volume', value: 'volume' },
              { label: 'risk_analysis.price', value: 'price' },
              { label: 'risk_analysis.m_to_m', value: 'mtom' },
            ]}
          />
          <ControlledRadioButtons
            name="splitBy"
            control={control}
            options={[
              { label: 'risk_analysis.split_by_focus', value: 'split_by_focus' },
              { label: 'risk_analysis.split_by_metric', value: 'split_by_metric' },
            ]}
            disabled={['mtom', 'price'].includes(metricGroup)}
          />
        </div>
        <div className="d-flex">
          <ControlledRadioButtons
            name="display"
            control={control}
            options={[
              { label: 'common.table', value: 'table' },
              { label: 'common.chart', value: 'chart' },
            ]}
          />
          <Button
            size="xs"
            variant="primary-2"
            type="submit"
            icon="Download"
            className="ml-4"
            data-cy="export-submit-button"
            text="common.download"
            onClick={debounce(() => {
              window.location.href = exportUrl;
            })}
          />
        </div>
      </div>
    </div>
  );
};

export const RiskAnalysisSensitivityReportHeader = ({
  exportUrl,
  control,
}: {
  exportUrl: string;
  control: Control<RiskAnalysisFormFilters>;
}) => {
  return (
    <div className={classNames(styles['display-filters'], 'pt-3')}>
      <div>
        <ControlledSelect
          name="primaryFocus"
          control={control}
          options={FOCUS_OPTIONS}
          placeholder="common.focus"
          className={styles.select}
          isClearable
        />
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div className="d-flex">
          <ControlledRadioButtons
            name="metricGroup"
            control={control}
            options={[
              { label: 'risk_analysis.all', value: 'all' },
              { label: 'Price Outright', value: 'price_outright' },
              { label: 'Profile Risk', value: 'profile_risk' },
              { label: 'Balancing Risk', value: 'balancing_risk' },
            ]}
          />
        </div>
        <div className="d-flex">
          <ControlledRadioButtons
            name="display"
            control={control}
            options={[
              { label: 'common.table', value: 'table' },
              { label: 'common.chart', value: 'chart' },
            ]}
          />

          <Button
            size="xs"
            variant="primary-2"
            type="submit"
            icon="Download"
            className="ml-4"
            data-cy="export-submit-button"
            text="common.download"
            onClick={debounce(() => {
              window.location.href = exportUrl;
            })}
          />
        </div>
      </div>
    </div>
  );
};
