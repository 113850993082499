import React, { useDeferredValue, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { v2_green_star_risks_path, v2_green_star_valuations_path } from '@utils/routes';
import classNames from 'classnames';
import { UseFormReturn } from 'react-hook-form';
import { SENSITIVITY_METRICS_FORM_NAMES_TO_LABELS } from '../constants';
import styles from '../styles.module.scss';
import {
  RiskAnalysisReportType,
  GreenstarReportData,
  RiskAnalysisFormFilters,
  GreenstarReportDataWithOneFocus,
  SensitivityMetricType,
} from '../types';
import { RiskAnalysisSensitivityReportHeader, RiskAnalysisValuationReportHeader } from './Header';
import { RiskAnalysisReportVisualisation } from './Visualization';

export const RiskAnalysisReport = ({
  type,
  areFiltersReady,
  form: { setValue, watch, control },
}: {
  areFiltersReady: boolean;
  type: RiskAnalysisReportType;
  form: UseFormReturn<RiskAnalysisFormFilters>;
}) => {
  const display = watch('display');
  const splitBy = watch('splitBy');
  const splitByMetric = splitBy !== 'split_by_focus';
  const metricGroup = watch('metricGroup');

  const defferedeMetricGroup = useDeferredValue(metricGroup);

  useEffect(() => {
    if (metricGroup === defferedeMetricGroup) return;

    if (['price', 'mtom'].includes(metricGroup) && splitBy === 'split_by_focus') {
      setValue('splitBy', 'split_by_metric');
    }

    if (metricGroup === 'all') {
      setValue('display', 'table');
    } else setValue('display', 'chart');
  }, [metricGroup, defferedeMetricGroup, splitBy, display, setValue, type]);

  const {
    primaryFocus,
    secondaryFocus,
    grappe,
    holding,
    spv,
    installations,
    energy,
    contract_type,
    apply_ownership_percentage,
    version,
    period,
  } = watch();

  const isReportNested = Boolean(secondaryFocus) || (type === 'sensitivity' && Boolean(primaryFocus));

  const latestStatuses = ['latest', 'latest_vs_last_month', 'latest_vs_last_year'];

  const status = latestStatuses.includes(version) ? 'latest' : version;
  let compare: string | undefined;

  if (version === 'latest_vs_last_month') {
    compare = 'last_month';
  } else if (version === 'latest_vs_last_year') {
    compare = 'last_year';
  }

  const generatedRoute = type === 'valuation' ? v2_green_star_valuations_path : v2_green_star_risks_path;

  const options = {
    primaryFocus,
    secondaryFocus,
    grappe,
    holding,
    spv,
    energy,
    contract_type,
    apply_ownership_percentage,
    start_date: period[0],
    end_date: period[1],
    site_name: installations,
    status,
    compare,
  };

  const url = generatedRoute(options);
  const exportUrl = generatedRoute({ ...options, format: 'xls' });

  const reportQuery = useQuery({
    queryKey: [url],
    enabled: areFiltersReady,
    queryFn: async (): Promise<GreenstarReportData> => {
      const response = await fetch(url);

      return response.json();
    },
  });

  const [lastValidData, setLastValidData] = useState(reportQuery.data);

  if (reportQuery.data && reportQuery.data !== lastValidData) {
    setLastValidData(reportQuery.data);
  }

  const data = areFiltersReady ? reportQuery.data : lastValidData;

  return (
    <div>
      {type === 'valuation' && (
        <>
          <RiskAnalysisValuationReportHeader
            control={control}
            exportUrl={exportUrl}
            focus={[primaryFocus || '', secondaryFocus]}
            metricGroup={metricGroup}
          />
          <div className="p-4 pt-3">
            {data && (
              <RiskAnalysisReportVisualisation
                data={data}
                isReportNested={isReportNested}
                isSplitByMetric={splitByMetric}
                type={type}
                metricGroup={metricGroup}
                display={display}
              />
            )}
          </div>
        </>
      )}
      {type === 'sensitivity' && (
        <>
          <RiskAnalysisSensitivityReportHeader control={control} exportUrl={exportUrl} />
          <div
            className={classNames('p-4 pt-3', {
              [styles['chart-grid']]: display === 'chart' && type === 'sensitivity',
            })}
          >
            {data &&
              (primaryFocus ? (
                ['Price Outright', 'Balancing Risk', 'Profile Risk']
                  .filter(
                    (category) =>
                      metricGroup === 'all' ||
                      SENSITIVITY_METRICS_FORM_NAMES_TO_LABELS[metricGroup as SensitivityMetricType] === category,
                  )
                  .map((category) => (
                    <div key={category}>
                      <RiskAnalysisReportVisualisation
                        key={category}
                        title={category}
                        data={data[category] as GreenstarReportDataWithOneFocus}
                        isReportNested={false}
                        isSplitByMetric={false}
                        type={type}
                        metricGroup="all"
                        display={display}
                      />
                    </div>
                  ))
              ) : (
                <RiskAnalysisReportVisualisation
                  data={data}
                  isReportNested={false}
                  isSplitByMetric={false}
                  type={type}
                  metricGroup="all"
                  display={display}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};
