import React, { useContext } from 'react';
import { Restricted } from '@components/Restricted';
import { AltContent } from '@GDM/AltContent';
import { Col, Row } from '@GDM/layout';
import { Map } from '@GDM/Map';
import { useDashboardMeter } from '@pages/DashboardMeter/useDashboardMeter';
import { Meter } from '@utils/types/meter';
import { InstallationMap } from 'pages/ui/shared/maps';
import styles from '../characteristics.module.scss';
import { Panels } from '../shared';
import { Books } from './Books';
import { BusinessPlan } from './BusinessPlan/BusinessPlan';
import Inverters from './Inverters';
import MeterInfo from './MeterInfo';
import { meterInfoContext } from './MeterInfo/meter-info.context';

export const MeterCharacteristics = ({ meter }: { meter: Meter }) => {
  const { book } = useDashboardMeter();
  const { lat, lng } = useContext(meterInfoContext);

  if (!meter) return <AltContent />;

  return (
    <>
      <Row>
        {!!lat && !!lng && (
          <Col xl={4} className={styles['map-container']}>
            <Map center={[Number(lat), Number(lng)]}>
              <InstallationMap name={meter.name} lat={Number(lat)} lng={Number(lng)} />
            </Map>
          </Col>
        )}
        <Col>
          <MeterInfo meter={meter} isDummy={!!meter.has_only_dummy_contracts || book?.has_only_dummy_contracts} />
        </Col>
      </Row>

      <div className={styles.configs}>
        <Restricted permissions={['display:view_monitoring_dashboard_meter_business_plan']}>
          <div className={styles.column}>
            <div className={styles.item}>
              <BusinessPlan installationName={meter.name} />
            </div>
          </div>
        </Restricted>

        <div className={styles.column}>
          {meter.installation?.energy === 'solar' && (
            <div className={styles.item}>
              <Panels meterName={meter.name} />
            </div>
          )}

          <Books meter={meter} />

          {!!meter.has_inverters && (
            <div className={styles.item}>
              <Inverters meterName={meter.name} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
