import React, { useMemo } from 'react';
import { Icon } from '@GDM/Icon/Icon';
import { NumberCell } from '@GDM/Table/Cells/NumberCell/NumberCell';

import { AcceptableUnits } from '@GDM/ValueWithUnit';
import useTranslation from '@hooks/useTranslation';
import { FOCUS_VALUE_TO_LABEL } from '@pages/RiskAnalysis/constants';
import { getUnitFromMetric } from '@pages/RiskAnalysis/utils';
import { AccessorKeyColumnDef } from '@tanstack/react-table';
import classNames from 'classnames';
import styles from '../../styles.module.scss';
import {
  GreenstarFocusData,
  GreenstarReportData,
  GreenstarReportDataWithOneFocus,
  GreenstarReportMetricValues,
} from '../../types';

type GreenstarRow = {
  name: string;
  subRows: GreenstarRow[];
  values: GreenstarReportMetricValues;
  unit: string;
};

export const useRiskAnalysisTable = (data: GreenstarReportData, isReportNested: boolean) => {
  const { t } = useTranslation();

  const { rows, dates } = useMemo(() => {
    const dateSet = new Set<string>();
    const rows: GreenstarRow[] = [];

    Object.entries(data).forEach(
      ([focus1, focusDataOrMetricValuesForEachDates]: [
        string,
        GreenstarFocusData | GreenstarReportDataWithOneFocus,
      ]) => {
        const row: GreenstarRow = {
          name: t(FOCUS_VALUE_TO_LABEL[focus1] || focus1),
          subRows: [],
          values: {},
          unit: getUnitFromMetric(focus1),
        };
        rows.push(row);
        let metricRows: Record<string, GreenstarRow> = {};

        Object.entries(focusDataOrMetricValuesForEachDates).forEach(
          ([dateOrSecondaryFocus, metricValuesOrFocusData]: [
            string,
            GreenstarReportMetricValues | GreenstarFocusData,
          ]) => {
            if (!isReportNested) {
              dateSet.add(dateOrSecondaryFocus);
              row.values[dateOrSecondaryFocus] = '';

              Object.entries(metricValuesOrFocusData).forEach(([metric, value]) => {
                metricRows[metric] = {
                  ...metricRows[metric],
                  name: metric,
                  subRows: [],
                  unit: getUnitFromMetric(metric) || row.unit,
                  values: {
                    ...metricRows[metric]?.values,
                    [dateOrSecondaryFocus]: value,
                  },
                };
              });

              return;
            }

            const subRow: GreenstarRow = {
              name: t(FOCUS_VALUE_TO_LABEL[dateOrSecondaryFocus] || dateOrSecondaryFocus),
              subRows: [],
              values: {},
              unit: getUnitFromMetric(dateOrSecondaryFocus) || row.unit,
            };

            row.subRows.push(subRow);

            metricRows = {};
            Object.entries(metricValuesOrFocusData).forEach(([date, values]: [string, GreenstarReportMetricValues]) => {
              dateSet.add(date);
              row.values[date] = '';
              subRow.values[date] = '';
              Object.entries(values).forEach(([metric, value]) => {
                metricRows[metric] = {
                  ...metricRows[metric],
                  name: metric,
                  subRows: [],
                  unit: getUnitFromMetric(metric) || getUnitFromMetric(dateOrSecondaryFocus) || row.unit,
                  values: {
                    ...metricRows[metric]?.values,
                    [date]: value,
                  },
                };
              });
            });

            subRow.subRows.push(...Object.values(metricRows));
          },
        );

        if (!isReportNested) row.subRows.push(...Object.values(metricRows));
        else {
          row.subRows.sort((a, b) => a.name.localeCompare(b.name));
        }
      },
    );

    rows.sort((a, b) => a.name.localeCompare(b.name));
    const dates = [...dateSet.values()].sort();

    return { dates, rows };
  }, [data, isReportNested, t]);

  const columns = useColumns(dates, isReportNested);

  return { columns, rows, dates };
};

const useColumns = (
  dates: string[],
  isReportNested: boolean,
): (AccessorKeyColumnDef<GreenstarRow> & { id: string })[] => {
  return useMemo(() => {
    return [
      {
        id: 'name',
        header: ({ table }) => {
          return (
            <Icon
              name={table.getIsSomeRowsExpanded() ? 'ChevronDown' : 'ChevronRight'}
              className="cursor-pointer select-none"
              size={14}
              onClick={() => {
                table.toggleAllRowsExpanded();
              }}
            />
          );
        },
        filterFn: (row, columnId, blacklist: string[]) => {
          const rowValue: string = row.getValue(columnId);

          return !blacklist.includes(rowValue);
        },
        accessorKey: 'name',
        cell: ({ row, getValue }) => (
          <div
            className={classNames({
              [styles['leaf-row']]: isReportNested ? row.depth === 2 : row.depth === 1,
              'pl-4': isReportNested && row.depth === 1,
            })}
          >
            {(isReportNested ? row.depth < 2 : row.depth === 0) && row.subRows.length > 0 && (
              <Icon
                name={row.getIsExpanded() ? 'ChevronDown' : 'ChevronRight'}
                className="cursor-pointer select-none"
                size={14}
                onClick={() => {
                  row.toggleExpanded();
                }}
              />
            )}{' '}
            {getValue<string>()}
          </div>
        ),
      },
      ...(dates.map<AccessorKeyColumnDef<GreenstarRow> & { id: string }>((dateKey) => {
        return {
          id: dateKey,
          header: dateKey,
          cell: ({ getValue, row }) => <NumberCell value={getValue()} unit={row.original.unit as AcceptableUnits} />,
          accessorKey: `values.${dateKey}`,
        };
      }) || []),
    ];
  }, [dates, isReportNested]);
};
