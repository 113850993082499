import React, { ReactNode, useMemo, useState } from 'react';
import { Meter } from '@utils/types/meter';
import { ReactiveConstraint } from '@utils/types/reactive_constraint';
import { MeterInfoContextType, meterInfoContext } from './MeterInfo/meter-info.context';

export const MeterCharacteristicsProvider = ({ meter, children }: { meter: Meter; children: ReactNode }) => {
  const [lat, setLat] = useState(meter.coordinates?.lat);
  const [lng, setLng] = useState(meter.coordinates?.lng);
  const [nominal_power, setNominalPower] = useState(meter.nominal_power);
  const [pr_expected, setPrExpected] = useState(meter.prexpected);
  const [reactiveConstraints, setReactiveConstraints] = useState<Partial<ReactiveConstraint>[] | undefined>(
    meter.reactive_constraints,
  );
  const [client_id, setClientId] = useState(meter.client_id);
  const [facturation_type, setInvoicingTypeRawString] = useState(meter.facturation_type);
  const [grid_name, setGridName] = useState(meter.grid_name);
  const [commissioning_date, setCommissioningDate] = useState(meter.commissioning_date);
  const [maxValue, setMaxValue] = useState<number | undefined>(meter.maxvalue);
  const [erpCode, setErpCode] = useState(meter.installation?.erp_code);
  const [siret, setSiret] = useState(meter.installation?.siret);

  const contextValue = useMemo<MeterInfoContextType>(
    () => ({
      nominal_power,
      setNominalPower,
      lat,
      setLat,
      lng,
      setLng,
      pr_expected,
      setPrExpected,
      reactiveConstraints,
      setReactiveConstraints,
      client_id,
      setClientId,
      facturation_type,
      setInvoicingTypeRawString,
      grid_name,
      setGridName,
      commissioning_date,
      setCommissioningDate,
      maxValue,
      setMaxValue,
      erpCode,
      setErpCode,
      siret,
      setSiret,
    }),
    [
      nominal_power,
      lat,
      lng,
      pr_expected,
      reactiveConstraints,
      client_id,
      facturation_type,
      grid_name,
      commissioning_date,
      maxValue,
      erpCode,
      siret,
    ],
  );

  return <meterInfoContext.Provider value={contextValue}>{children}</meterInfoContext.Provider>;
};
