import React, { ReactNode, useMemo, useState } from 'react';
import Installation from '@utils/types/installation';
import { InstallationInfoContextType, installationInfoContext } from './InstallationInfo/installation-info.context';

export const InstallationCharacteristicsProvider = ({
  installation,
  children,
}: {
  installation: Installation;
  children: ReactNode;
}) => {
  const [lat, setLat] = useState(installation.latitude || null);
  const [lng, setLng] = useState(installation.longitude || null);
  const [client_id, setClientId] = useState(installation.external_ref || null);
  const [erpCode, setErpCode] = useState(installation.erp_code);
  const [siret, setSiret] = useState(installation.siret || null);

  const contextValue = useMemo<InstallationInfoContextType>(
    () => ({
      lat,
      setLat,
      lng,
      setLng,
      client_id,
      setClientId,
      erpCode,
      setErpCode,
      siret,
      setSiret,
    }),
    [client_id, erpCode, lat, lng, siret],
  );

  return <installationInfoContext.Provider value={contextValue}>{children}</installationInfoContext.Provider>;
};
